import { useState, useEffect } from "react";
import { addresses, TOKEN_DECIMALS } from "../../constants";
import { getTokenImage } from "../../helpers";
import { useSelector } from "react-redux";
import { Link, SvgIcon, Popper, Button, Paper, Typography, Divider, Box, Fade, Slide } from "@material-ui/core";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-fill.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/arrow-up.svg";
import "./ohmmenu.scss";
import { useWeb3Context } from "../../hooks/web3Context";
import { ethers } from 'ethers'
import SohmImg from "src/assets/tokens/logo.svg";


function OhmMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const isEthereumAPIAvailable = window.ethereum;
  const { chainID, provider, address } = useWeb3Context();

  const networkID = chainID;

  const OHM_ADDRESS = addresses[networkID].OHM_ADDRESS;
  const SOHM_ADDRESS = addresses[networkID].SOHM_ADDRESS;

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClaimU = async () => {
    if (address && provider && chainID) {
      try {
        const signer = provider.getSigner()
        const contract = new ethers.Contract("0x2d4dD7D4DA9AD2fe8B56A218137a5796dEAd0860", [
          {
            "inputs": [],
            "name": "claimU",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          {
            "inputs": [
              {
                "internalType": "address",
                "name": "_u",
                "type": "address"
              }
            ],
            "stateMutability": "nonpayable",
            "type": "constructor"
          },
          {
            "anonymous": false,
            "inputs": [
              {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
              },
              {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
              }
            ],
            "name": "OwnershipTransferred",
            "type": "event"
          },
          {
            "inputs": [],
            "name": "renounceOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          {
            "inputs": [
              {
                "internalType": "address",
                "name": "newOwner_",
                "type": "address"
              }
            ],
            "name": "transferOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          },
          {
            "inputs": [],
            "name": "owner",
            "outputs": [
              {
                "internalType": "address",
                "name": "",
                "type": "address"
              }
            ],
            "stateMutability": "view",
            "type": "function"
          },
          {
            "inputs": [],
            "name": "usdc",
            "outputs": [
              {
                "internalType": "address",
                "name": "",
                "type": "address"
              }
            ],
            "stateMutability": "view",
            "type": "function"
          }
        ], signer)
        await contract.claimU()
      } catch (error) {
        console.log({error})
      }

    }
  }

  const open = Boolean(anchorEl);
  const id = "ohm-popper";
  // const daiAddress = dai.getAddressForReserve(networkID);
  // const fraxAddress = frax.getAddressForReserve(networkID);
  return (
    <Box
      component="div"
      onMouseEnter={e => handleClick(e)}
      onMouseLeave={e => handleClick(e)}
      id="ohm-menu-button-hover"
    >
      <Button id="ohm-menu-button" variant="contained" color="secondary" size="large" title="PRIVATE" aria-describedby={id}>
        <SvgIcon component={InfoIcon} color="primary" />
        <Typography>PRIVATE</Typography>
      </Button>

      {/* <Button id="ohm-menu-button" size="large" variant="contained"  >
        <Typography> {ChainText[chainID]} </Typography>
      </Button> */}

      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start" transition>
        {({ TransitionProps }) => {
          return (
            <Fade {...TransitionProps} timeout={100}>
              <Paper className="ohm-menu" elevation={1}>
                <Box component="div" className="buy-tokens">
                  <Link
                    href={`https://pancakeswap.finance/swap?inputCurrency=0x1dAf7bc272D8124Ff68318107f63bD0722889590&outputCurrency=0xE7B96AdDCF9Ef1c51B30fa77Ff4239DBA9902b5B&chain=bscTestnet`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button size="large" variant="contained" color="secondary" fullWidth>
                      <Typography align="left">
                        Buy on Pancake Swap <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" />
                      </Typography>
                    </Button>
                  </Link>

                  {/* <Link
                    href={`https://dexscreener.com/fantom/0x937813380c7b98a66Afa5992bf2231E3E5913eF4`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button size="large" variant="contained" color="secondary" fullWidth>
                      <Typography align="left">
                        Charts <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" />
                      </Typography>
                    </Button>
                  </Link> */}
                </Box>

                {isEthereumAPIAvailable ? (
                  <Box className="add-tokens">
                    <Divider color="secondary" />

                    <p>ADD TOKEN TO WALLET</p>
                    <Button
                      size="large"
                      variant="contained"
                      color="secondary"
                      onClick={addTokenToWallet("PRIVATE", OHM_ADDRESS, SohmImg)}
                    >
                      <Typography>PRIVATE</Typography>
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={addTokenToWallet("sPRIVATE", SOHM_ADDRESS, SohmImg)}
                    >
                      <Typography>sPRIVATE</Typography>

                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={handleClaimU}
                    >
                      <Typography>ClaimUSDC</Typography>

                    </Button>
                  </Box>
                ) : null}

                {/* <Divider color="secondary" />
                <Link
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button size="large" variant="contained" color="secondary" fullWidth>
                    <Typography align="left">Unstake LP Token</Typography>
                  </Button>
                </Link> */}
              </Paper>
            </Fade>
          );
        }}
      </Popper>
    </Box>
  );
}



const addTokenToWallet = (tokenSymbol, tokenAddress, tokenImage) => async () => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: TOKEN_DECIMALS,
            image: tokenImage,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};




export default OhmMenu;
