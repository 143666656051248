// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 3; //ARB
//export const BLOCK_RATE_SECONDS = 3; //TODO:BSC
export const TOKEN_DECIMALS = 9;


interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  250: {

  },
  /*
  SAME: 0xE7B96AdDCF9Ef1c51B30fa77Ff4239DBA9902b5B
USDC: 0x1dAf7bc272D8124Ff68318107f63bD0722889590
treasury: 0xAF8b2FBC29BdFad558CC81e7320FCeC5209E147c        
lpBondCalculator: 0xB69B8B9f40c7427A1105C9a7E26f206976c4e36D
distributor: 0x2546eC1aD6f31936409007CF0A0a4192cae3C226     
sZenvo: 0x9E19eB434fd5cb9B6A6AaFC06cb024d9f6e12521
staking: 0x3030A132fF1212BF5B7ecBE353F2626558781f05
stakingWarmup: 0x85678Ae77bf50cbE81676490821d3705E4aaA2Fc
stakingHelper: 0x1E1e32c99Bb50Cfa8b48f7f962a6042C41Ff75ef
fic_usdc_lp: 0x5C4d76f83b08F751F792e6eDae7449c4b34C1103
lpBond: 0xbf9bE7a02599060C08f0eb1159EF5aa282107145
lpBondHelper: 0x44D794408B0Fe7a627b40354e885a6eb860B1172
redeemHelper: 0xCa4c713f8b9a7fFe0a787c21C625868Ece50150B
  */
  97: {
    OHM_ADDRESS: "0xE7B96AdDCF9Ef1c51B30fa77Ff4239DBA9902b5B", //
    STAKING_ADDRESS: "0x3030A132fF1212BF5B7ecBE353F2626558781f05",
    STAKING_HELPER_ADDRESS: "0x1E1e32c99Bb50Cfa8b48f7f962a6042C41Ff75ef",
    SOHM_ADDRESS: "0x9E19eB434fd5cb9B6A6AaFC06cb024d9f6e12521",
    DISTRIBUTOR_ADDRESS: "0x2546eC1aD6f31936409007CF0A0a4192cae3C226",
    BONDINGCALC_ADDRESS: "0xB69B8B9f40c7427A1105C9a7E26f206976c4e36D",
    TREASURY_ADDRESS: "0xAF8b2FBC29BdFad558CC81e7320FCeC5209E147c",
    USDC_ADDRESS: "0x1dAf7bc272D8124Ff68318107f63bD0722889590", //
    DAO_ADDRESS: '0xE6ce6781a8b1367A77D1494a6E57Ee8A0Ce64979',
    LP_ADDRESS: '0x5C4d76f83b08F751F792e6eDae7449c4b34C1103',
    REDEEM_HELPER_ADDRESS: "0xCa4c713f8b9a7fFe0a787c21C625868Ece50150B",
    ASOHM_ADDRESS: '0x4c0343c5605bd8039AD242C0d8B7211186Ec3253', //
    ASOHM_MIGRATION_ADDRESS: '0x1E1e8EfdB3e8A84CD726bADdeB090b2874c85907', //

  }
};



