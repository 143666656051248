import { trim } from "../../helpers";
import BondLogo from "../../components/BondLogo";
import { Box, Button, Link, Paper, Typography, TableRow, TableCell, SvgIcon, Slide } from "@material-ui/core";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
import { NavLink } from "react-router-dom";
import "./choosebond.scss";
import { Skeleton } from "@material-ui/lab";
import useBonds from "src/hooks/Bonds";
import lpimg from "../../assets/tokens/usdc-private.png"

export function BondDataCard({ bond }) {
  const { loading } = useBonds();
  const isBondLoading = !bond.bondPrice ?? true;

  return (
    (bond.name != 'sohm_wbnb_lp_old') ? <Slide direction="up" in={true}>
      <Paper id={`${bond.name}--bond`} className="bond-data-card ohm-card">
        <div className="bond-pair">
        {
          bond.name == 'PRIVATE_usdc_lp' ? 
          <img className="bond-lp" src={lpimg} style={{width: `68px`}} />
          :
          <BondLogo bond={bond} />
        }
          <div className="bond-name">
            <Typography>{bond.displayName}</Typography>
            {bond.isLP && (
              <div>
                {/* <Link href={bond.lpUrl} target="_blank"> */}
                <Link component={NavLink} to={`/bonds/${bond.name}`}>
                  <Typography variant="body1">
                    1-click Purchase
                    <SvgIcon component={ArrowUp} htmlColor="#A3A3A3" />
                  </Typography>
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className="data-row">
          <Typography>Price</Typography>
          <Typography className="bond-price">
            <>{isBondLoading ? <Skeleton width="50px" /> : `$${trim(bond.bondPrice, 2)}`}</>
          </Typography>
        </div>

        <div className="data-row">
          <Typography>ROI</Typography>
          <Typography>{isBondLoading ? <Skeleton width="50px" /> : `${trim(bond.bondDiscount * 100, 2)}%`}</Typography>
        </div>

        <div className="data-row">
          <Typography>Purchased</Typography>
          <Typography>
            {isBondLoading ? (
              <Skeleton width="80px" />
            ) : (
              // new Intl.NumberFormat("en-US", {
              //   style: "currency",
              //   currency: "USD",
              //   maximumFractionDigits: 0,
              //   minimumFractionDigits: 0,
              // }).format(bond.purchased)
              `$${new Intl.NumberFormat("en-US").format(trim(bond.purchased, 2))}`
            )}
          </Typography>
        </div>
        <Link component={NavLink} to={`/bonds/${bond.name}`}>
          <Button variant="outlined" color="primary" fullWidth disabled={!bond.isAvailable}>
            <Typography variant="h5">
              {!bond.isAvailable ? `Sold Out` : `Bond ${bond.displayName}`}
            </Typography>
          </Button>
        </Link>
      </Paper>
    </Slide> : ""
  );
}

export function BondTableData({ bond }) {
  // Use BondPrice as indicator of loading.
  const isBondLoading = !bond.bondPrice ?? true;
  // const isBondLoading = useSelector(state => !state.bonding[bond]?.bondPrice ?? true);


  return (
    (bond.name != 'sohm_wbnb_lp_old') ? <TableRow id={`${bond.name}--bond`} >
      <TableCell align="left" className="bond-name-cell">
        
        {
          bond.name == 'PRIVATE_usdc_lp' ? 
          <img className="bond-lp" src={lpimg} style={{width: `68px`}} />
          :
          <BondLogo bond={bond} />
        }
        <div className="bond-name">
          <Typography variant="body1">{bond.displayName}</Typography>
          {bond.isLP && (
            // <Link color="primary" href={bond.lpUrl} target="_blank">
            <Link component={NavLink} to={`/bonds/${bond.name}`}>
              <Typography variant="body1" style={{ color: "#A3A3A3" }}>
                1-click Purchase
                <SvgIcon component={ArrowUp} htmlColor="#A3A3A3" />
              </Typography>
            </Link>
          )}
        </div>
      </TableCell>
      <TableCell align="left">
        <Typography>
          <>
            {/* <span className="currency-icon">$</span> */}
            {isBondLoading ? <Skeleton width="50px" /> : `$${trim(bond.bondPrice, 2)}`}
          </>
        </Typography>
      </TableCell>
      <TableCell align="left">{isBondLoading ? <Skeleton /> : `${trim(bond.bondDiscount * 100, 2)}%`}</TableCell>
      <TableCell align="right">
        {isBondLoading ? (
          <Skeleton />
        ) : (
          // new Intl.NumberFormat("en-US", {
          //   style: "currency",
          //   currency: "USD",
          //   maximumFractionDigits: 0,
          //   minimumFractionDigits: 0,
          // }).format(bond.purchased)
          `$${new Intl.NumberFormat("en-US").format(trim(bond.purchased, 2))}`
        )}
      </TableCell>
      <TableCell>
        <Link component={NavLink} to={`/bonds/${bond.name}`}>
          <Button variant="outlined" fullWidth color="primary" disabled={!bond.isAvailable}>
            <Typography variant="h6"> {!bond.isAvailable ? `Sold Out` : `Bond`}</Typography>
          </Button>
        </Link>
      </TableCell>
    </TableRow> : ""
  );
}
