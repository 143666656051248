import { useEffect, useState } from "react";
import { Grid, Typography, Zoom, TableRow, TableCell, Grow, Box, Tooltip } from "@material-ui/core";
import { display } from '@material-ui/system';
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { trim, formatCurrency } from "../../helpers";
import "./treasury-dashboard.scss";
import { allBondsMap } from "src/helpers/AllBonds";
function TreasuryDashboard() {

  const accountBonds = useSelector(state => {
    let bondPending = 0;
    let bondClaimable = 0;
    for (const bond in state.account.bonds) {
      if (state.account.bonds[bond].interestDue > 0) {
        bondPending += Number(state.account.bonds[bond].interestDue)
        bondClaimable += Number(state.account.bonds[bond].pendingPayout)
      }
    }
    return { bondPending, bondClaimable }
  });

  const warmBalance = useSelector(state => {
    return state.account.balances?.warmBalance || 0;
  });

  const runway = useSelector(state => {
    return state.app.runWay > 0 ? state.app.runWay : 0;
  });
  const luckRate = useSelector(state => {
    return state.app.luckRate > 0 ? state.app.luckRate : 0;
  });
  const TotalLuckPrice = useSelector(state => {
    return state.app.TotalLuckPrice > 0 ? state.app.TotalLuckPrice : 0;
  });
  const treasuryReBalance = useSelector(state => {
    return state.app.TotalLuckPrice > 0 ? state.app.treasuryReBalance : 0;
  });
  const ohmBalance = useSelector(state => {
    return state.account?.balances?.ohm || 0;
  });
  const sohmBalance = useSelector(state => {
    return state.account?.balances?.sohm || 0;
  });
  const marketPrice = useSelector(state => {
    return state.app.marketPrice || 0;
  });
  const Pledgerate = useSelector(state => {
    return state.app.Pledgerate || 0;
  });
  const totalSupply = useSelector(state => {
    return state.app.totalSupply || 0;
  });
  const marketCap = useSelector(state => {
    return state.app.marketCap || 0;
  });
  const stakingAPY = useSelector(state => {
    return state.app.stakingAPY || 0;
  });
  const currentIndex = useSelector(state => {
    return state.app.currentIndex || 0;
  });
  const daoBal = useSelector(state => {
    return state.app.daoBal || 0;
  });
  const treasuryBalance = useSelector(state => {
    if (state.bonding.loading == false) {
      let tokenBalances = 0;
      for (const bond in allBondsMap) {
        if (state.bonding[bond]) {
          if (state.bonding[bond].bond != "sohm_wbnb_lp_old") {
            tokenBalances += state.bonding[bond].purchased;
          }
        }
      }
      return tokenBalances || 0;
    }
  });
  const isAppLoading = useSelector(state => !state.app?.marketPrice ?? true);
  const stakingRebase = useSelector(state => {
    return state.app.stakingRebase || 0;
  });
  const fiveDayRate = useSelector(state => {
    return state.app.fiveDayRate;
  });
  const stakingRebasePercentage = trim(stakingRebase * 100, 4);
  const nextRewardValue = trim((stakingRebasePercentage / 100) * sohmBalance, 2);
  const oneDayRate = useSelector(state => {
    return state.app.oneDayRate || 0;
  });
  const EstDaily = oneDayRate * sohmBalance
  return (
    <div id="treasury-dashboard-view">
      <Grid container spacing={2} className="dashboardCard">

        <Grid className="ohm-card-1-f" >  {/* item={true} lg={3} md={6} xs={12} */}
          <Zoom in={true}>
            <div className="ohm-card-1">
              <Typography variant="h6" className="Balances Kano-regular">Balances</Typography>
              <div className="ohm-card-top">
                <div className="total-value">
                  <Typography className="USD Kano-regular" variant="h6">Total Value (USD): </Typography>
                  {isAppLoading || marketPrice == 0 ? <Skeleton width="100px" /> :
                    <Typography className="percent Arial-Black" variant="h5">${new Intl.NumberFormat("en-US").format(((Number(ohmBalance) + Number(sohmBalance)) * marketPrice))}</Typography>
                  }
                </div>
                <div className="M-B-D">
                  <Typography className="MBD Kano-regular" variant="h6">PRIVATE: </Typography>
                  {isAppLoading ? <Skeleton width="100px" /> :
                    <Typography className="value Arial-Black" variant="h5">{new Intl.NumberFormat("en-US").format(trim(ohmBalance, 2))}  </Typography>
                  }
                </div>
                <div className="s-M-B-D">
                  <Typography className="sMBD Kano-regular" variant="h6">sPRIVATE: </Typography>
                  {isAppLoading ? <Skeleton width="100px" /> :
                    <Typography className="value Arial-Black" variant="h5"> {new Intl.NumberFormat("en-US").format(trim(sohmBalance, 2))}</Typography>
                  }
                </div>
              </div>
              <Typography variant="h6" className="Rewards">Rewards</Typography>
              <div className="ohm-card-bottom">
                <div className="est-daily">
                  <Typography className="estdaily Kano-regular" variant="h6">Est. Daily: </Typography>
                  {isAppLoading ? <Skeleton width="100px" /> :
                    <Typography className="percent Arial-Black" variant="h5"> {new Intl.NumberFormat("en-US").format(trim(EstDaily, 2))} </Typography>
                  }
                </div>
                <div className="next-epoch">
                  <Typography className="nextepoch Kano-regular" variant="h6">Next Epoch: </Typography>
                  {isAppLoading ? <Skeleton width="100px" /> :
                    <Typography className="value Arial-Black" variant="h5"> {nextRewardValue} </Typography>
                  }
                </div>
                <div className="in-warmup">
                  <Typography className="inwarmup Kano-regular" variant="h6">In Warmup: </Typography>
                  {isAppLoading ? <Skeleton width="100px" /> :
                    <Typography className="value Arial-Black" variant="h5"> {new Intl.NumberFormat("en-US").format(trim(warmBalance, 2))} </Typography>
                  }
                </div>
              </div>
            </div>
          </Zoom>
        </Grid>


        {/* <Grid className="ohm-card-2-f">
          <Zoom in={true}>
            <div className="ohm-card-2">
              <Typography variant="h6" className="Minting Kano-regular">Bond</Typography>  
              <div className="ohm-card-top">
                <div className="pend-ing">
                  <Typography className="pending Kano-regular" variant="h6">Pending: </Typography>
                  {isAppLoading ? <Skeleton width="100px" /> :
                    <Typography className="value Arial-Black" variant="h5"> {new Intl.NumberFormat("en-US").format(trim(accountBonds.bondPending, 4))} </Typography>
                  }
                </div>
                <div className="claim-able">
                  <Typography className="claimable Kano-regular" variant="h6">Claimable: </Typography>
                  {isAppLoading ? <Skeleton width="100px" /> :
                    <Typography className="value Arial-Black" variant="h5"> {new Intl.NumberFormat("en-US").format(trim(accountBonds.bondClaimable, 4))} </Typography>
                  }
                </div>
            </div>
          </Zoom>
        </Grid> */}

        <Grid className="ohm-card-3-f" >
          <Zoom in={true}>
            <div className="ohm-card-3">

              <Typography variant="h6" className="ProtocolStatistics Kano-regular">Protocol Statistics</Typography>
              <div className="ohm-card-top">

                <div className="current-price">
                  <Typography className="currentprice Kano-regular" variant="h6">Current Price: </Typography>
                  {isAppLoading || marketPrice == 0 ? <Skeleton width="100px" /> :
                    <Typography className="percent Arial-Black" variant="h5">{`$${new Intl.NumberFormat("en-US").format(trim(marketPrice, 2))}`}</Typography>
                  }
                </div>
                <div className="a-p-y">
                  <Typography className="apy Kano-regular" variant="h6">APY: </Typography>
                  {isAppLoading || stakingAPY == 0 ? <Skeleton width="100px" /> :
                    // <Typography style={{textOverflow:'ellipsis',overflow:'hidden'}} className="value Arial-Black" variant="h5"> 
                    // {new Intl.NumberFormat("en-US").format(stakingAPY * 100)}% </Typography> 
                    <Tooltip title={new Intl.NumberFormat("en-US").format(stakingAPY * 100).toString() + " %"} arrow>
                      <Typography className="value Arial-Black" variant="h5">
                        {new Intl.NumberFormat("en-US").format(stakingAPY * 100).length > 17 ?
                          (new Intl.NumberFormat("en-US").format(stakingAPY * 100).substring(0, 18) + "...")
                          : new Intl.NumberFormat("en-US").format(stakingAPY * 100)}% </Typography>
                    </Tooltip>
                    // <Box className="value Arial-Black" component="h5" sx={{textOverflow:'ellipsis',overflow:'hidden'}} >
                    // {new Intl.NumberFormat("en-US").format(stakingAPY * 100)}%
                    // </Box>
                  }
                </div>
                <div className="day-rate">
                  <Typography className="5dayrate Kano-regular" variant="h6">5-Day Rate: </Typography>
                  {isAppLoading || Pledgerate == 0 ? <Skeleton width="100px" /> :
                    <Typography className="value Arial-Black" variant="h5"> {trim(fiveDayRate * Math.pow(10, 2), 2)}% </Typography>
                  }
                </div>
                <div className="market-cap">
                  <Typography className="marketcap Kano-regular" variant="h6">Market Cap: </Typography>
                  {isAppLoading || marketCap == 0 ? <Skeleton width="100px" /> :
                    <Typography className="value Arial-Black" variant="h5">
                      {`$${new Intl.NumberFormat("en-US").format(trim(marketCap))}`}
                    </Typography>
                  }
                </div>
                <div className="circ-supply">
                  <Typography className="circsupply Kano-regular" variant="h6">Circ. Supply: </Typography>
                  {isAppLoading ? <Skeleton width="100px" /> :
                    <Typography className="value Arial-Black" variant="h5">
                      {new Intl.NumberFormat("en-US").format(trim((totalSupply - daoBal) / Math.pow(10, 9), 2))}
                    </Typography>
                  }
                </div>
                <div className="trea-sury">
                  <Typography className="treasury Kano-regular" variant="h6">Treasury: </Typography>
                  {isAppLoading || treasuryBalance == 0 ? <Skeleton width="100px" /> :
                    <Typography className="value Arial-Black" variant="h5">
                      {`$${new Intl.NumberFormat("en-US").format(trim(treasuryBalance))}`}
                    </Typography>
                  }
                </div>
                <div className="t-v-l">
                  <Typography className="tvl Kano-regular" variant="h6">TVL: </Typography>
                  {isAppLoading || TotalLuckPrice == 0 ? <Skeleton width="100px" /> :
                    <Typography className="value Arial-Black" variant="h5">
                      {`$${new Intl.NumberFormat("en-US").format(trim(TotalLuckPrice))}`}
                    </Typography>
                  }
                </div>
                <div className="backing-MBD">
                  <Typography className="backingMBD Kano-regular" variant="h6">Backing/$PRIVATE: </Typography>
                  {isAppLoading || treasuryBalance == 0 ? <Skeleton width="100px" /> :
                    <Typography className="value Arial-Black" variant="h5">
                      {`$${new Intl.NumberFormat("en-US").format(trim(treasuryBalance / ((totalSupply - daoBal) / Math.pow(10, 9)), 2))}`}
                    </Typography>
                  }
                </div>
                <div className="run-way">
                  <Typography className="runway Kano-regular" variant="h6">Runway: </Typography>
                  {isAppLoading || runway == 0 ? <Skeleton width="100px" /> :
                    <Typography className="value Arial-Black" variant="h5"> {trim(runway, 2)} Days </Typography>
                  }
                </div>
                <div className="M-B-D">
                  <Typography className="MBD Kano-regular" variant="h6">Index: </Typography>
                  {isAppLoading || currentIndex == 0 ? <Skeleton width="100px" /> :
                    <Typography className="value Arial-Black" variant="h5"> {trim(currentIndex, 4)} PRIVATE </Typography>
                  }
                </div>
              </div>
            </div>
          </Zoom>
        </Grid>


      </Grid>
    </div>
  );
}

export default TreasuryDashboard;
