import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import Social from "./Social";
import externalUrls from "./externalUrls";
import { ReactComponent as StakeIcon } from "../../assets/icons/stake.svg";
import { ReactComponent as BondIcon } from "../../assets/icons/bond.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
//import { ReactComponent as PoolTogetherIcon } from "../../assets/icons/33-together.svg";
import { ReactComponent as IdoIcon } from "../../assets/icons/ido.svg";
// import { ReactComponent as IgoIcon } from "../../assets/icons/igo.svg";
import { ReactComponent as CalculatorIcon } from "../../assets/icons/calculator.svg";
import { ReactComponent as DocsIcon } from "../../assets/icons/book.svg";

import { trim, shorten } from "../../helpers";
import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import useBonds from "../../hooks/Bonds";
import { Paper, Link, Box, Typography, SvgIcon, Icon } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import "./sidebar.scss";
import logo from "../../assets/images/logo.png";
function NavContent({ theme }) {
  const [isActive] = useState();
  const address = useAddress();
  const { bonds } = useBonds();
  const { chainID } = useWeb3Context();

  const [sidbarRoute, setSidbarRoute] = useState('')

  const logoIcon = logo;

  const checkPage = useCallback((match, location, page) => {
    const currentPath = location.pathname.replace("/", "");

    if (currentPath.indexOf("dashboard") >= 0 && page === "dashboard") {
      return true;
    }
    if (currentPath.indexOf("ido") >= 0 && page === "ido") {
      return true;
    }
    if (currentPath.indexOf("snowglobe") >= 0 && page === "snowglobe") {
      return true;
    }
    if (currentPath.indexOf("stake") >= 0 && page === "stake") {
      return true;
    }
    if ((currentPath.indexOf("bonds") >= 0 || currentPath.indexOf("choose_bond") >= 0) && page === "bonds") {
      return true;
    }
    return false;
  }, []);

  return (
    <Paper className="dapp-sidebar">
      <Box className="dapp-sidebar-inner" display="flex" justifyContent="space-between" flexDirection="column">
        <div className="dapp-menu-top">
          <Box className="branding-header">
            <img src={logoIcon}></img>
            <p>PRIVATE Finance</p>

            {address && (
              <div className="wallet-link">
                <Link href={`https://arbiscan.io/address/${address}`} target="_blank">
                  {shorten(address)}
                </Link>
              </div>
            )}
          </Box>

          <div className="dapp-menu-links">
            <div className="dapp-nav" id="navbarNav">

              <Link
                component={NavLink}
                id="dash-nav"
                to="/dashboard"
                isActive={(match, location) => {
                  setSidbarRoute(location.pathname)
                  return checkPage(match, location, "dashboard");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <div style={{ display: "flex" }}>
                  <Typography variant="h6" className={`sidbarIcon `} style={{ visibility: sidbarRoute === "/dashboard" ? "" : "hidden" }} />
                  <Typography variant="h6" className="Arial-Black">
                    <i class="iconfont icon-a-sj"></i>
                    Dashboard
                  </Typography>
                </div>
              </Link>

              <Link
                component={NavLink}
                id="stake-nav"
                to="/stake"
                isActive={(match, location) => {
                  setSidbarRoute(location.pathname)
                  return checkPage(match, location, "stake");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <div style={{ display: "flex" }}>
                  <Typography variant="h6" className={`sidbarIcon `} style={{ visibility: sidbarRoute === "/stake" ? "" : "hidden" }} />
                  <Typography variant="h6" className="Arial-Black">
                    <i class="iconfont icon-qb"></i>
                    Stake
                  </Typography>
                </div>
              </Link>

              <Link
                component={NavLink}
                id="snowglobe-nav"
                to="/snowglobe"
                isActive={(match, location) => {
                  return checkPage(match, location, "snowglobe");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <div style={{ display: "flex" }}>
                  <Typography variant="h6" className={`sidbarIcon `} style={{ visibility: sidbarRoute === "/snowglobe" ? "" : "hidden" }} />
                  <Typography variant="h6" className="Arial-Black">
                    <i class="iconfont icon-calc"></i>
                    Calculator
                  </Typography>
                </div>
              </Link>

              <Link
                component={NavLink}
                id="bond-nav"
                to="/bonds"
                isActive={(match, location) => {
                  return checkPage(match, location, "bonds");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <div style={{ display: "flex" }}>
                  <Typography variant="h6" className={`sidbarIcon `} style={{ visibility: sidbarRoute === "/bonds" ? "" : "hidden" }} />
                  <Typography variant="h6" className="Arial-Black">
                    <i class="iconfont icon-wallet"></i>
                    Bond
                  </Typography>
                </div>
              </Link>


              <div className="dapp-menu-data discounts">
                <div className="bond-discounts">
                  {bonds.map((bond, i) => (
                    bond.name != 'sohm_wbnb_lp_old' ? <Link component={NavLink} to={`/bonds/${bond.name}`} key={i} className={"bond"}>
                      {!bond.bondDiscount ? (
                        <Skeleton variant="text" width={"150px"} />
                      ) : (
                        <Typography variant="body2">
                          {bond.displayName}
                          <span className="bond-pair-roi">
                            {bond.bondDiscount && trim(bond.bondDiscount * 100, 2)}%
                          </span>
                        </Typography>
                      )}
                    </Link> : ""

                  ))}
                </div>
              </div>


              {/* <Link
                component={NavLink}
                id="ido-nav"
                to="/ido"
                isActive={(match, location) => {
                  return checkPage(match, location, "ido");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <div style={{ display: "flex" }}>
                  <Typography variant="h6" className={`sidbarIcon `} style={{ visibility: sidbarRoute === "/ido" ? "" : "hidden" }} />
                  <Typography variant="h6" className="Arial-Black">
                    <SvgIcon color="primary" component={IdoIcon} />
                    Redeem  
                  </Typography>
                </div>
              </Link> */}

              {/* <Link href={'https://docs.privatedao.money'} target="_blank">
                <div style={{ display: "flex" }}>
                  <Typography variant="h6" className={`sidbarIcon `} style={{ visibility: "hidden" }} />
                  <Typography variant="h6" className="Arial-Black">
                    <SvgIcon color="primary" component={DocsIcon} />
                    Docs
                  </Typography>
                </div>
              </Link> */}

            </div>
          </div>
        </div>

        <div className="dapp-menu-social">
          <Social />
        </div>

      </Box>
    </Paper>
  );
}

export default NavContent;
