import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Skeleton } from "@material-ui/lab";
import { ethers } from "ethers";
import {
	Box,
	Grid,
	Paper,
	Typography,
	Zoom,
	Stack,
	Button
} from "@material-ui/core";
import { useWeb3Context } from "../../hooks/web3Context";
import { abi as aSOHM } from "../../abi/aSOHM.json";
import { abi as aSOHMMigration } from "../../abi/aSOHMMigration.json";
import { error } from "../../slices/MessagesSlice";
import { getBalances } from "../../slices/AccountSlice";
import { addresses } from "src/constants";
import "./ido.scss";
import useIdo from "src/hooks/useIdo";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { clearPendingTxn, fetchPendingTxns } from 'src/slices/PendingTxnsSlice';
function Ido() {
	const isSmallScreen = useMediaQuery("(max-width: 733px)"); // change to breakpoint query
	const isVerySmallScreen = useMediaQuery("(max-width: 420px)");
	const { provider, chainID, address, connect } = useWeb3Context();

	const dispatch = useDispatch();
	const { alphaBalance, iceBalance, isApproving, DoApprove, handlerMigrate } = useIdo()

	const pendingTransactions = useSelector(state => {
		return state.pendingTransactions;
	});


	return (
		<div id="ido-info-view">
			<Zoom in={true}>
				<Paper className="ohm-card">
					<Grid container direction="column" spacing={2}>
						<Grid item>
							<Box className="card-header">
								<Typography variant="h5">IDO Claim</Typography>
							</Box>
						</Grid>
						<Grid item>
							<div className="stake-top-metrics">
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6} md={6} lg={6}>
										<div className="stake-apy">
											<Typography variant="h5" color="textSecondary">
												alphaPRIVATE Balance
											</Typography>
											<Typography variant="h4">
												{alphaBalance ? alphaBalance : 0}
											</Typography>
										</div>
									</Grid>

									<Grid item xs={12} sm={6} md={6} lg={6}>
										<div className="stake-tvl">
											<Typography variant="h5" color="textSecondary">
												PRIVATE Balance
											</Typography>
											<Typography variant="h4">
												{iceBalance ? iceBalance : 0}
											</Typography>
										</div>
									</Grid>
								</Grid>
							</div>
						</Grid>
						<div className="ido-area">
							<div className="options">
								{!address ?
									<Button variant="contained" color="primary" className="connect-button" onClick={connect} key={1}>
										Connect Wallet
									</Button>
									:
									(
										!isApproving ?
											<Button
												disabled={isPendingTxn(pendingTransactions, "approve_private")}
												variant="outlined"
												className="operationBtn"
												color="primary"
												onClick={() => { DoApprove() }}>
												<Typography variant="h6">{txnButtonText(pendingTransactions, "approve_private", "Approve")}</Typography>
											</Button> :
											<Button
												disabled={isPendingTxn(pendingTransactions, "claim_private")}
												variant="outlined"
												color="primary"
												className="operationBtn"
												onClick={() => { handlerMigrate() }}>
												<Typography variant="h6">{txnButtonText(pendingTransactions, "claim_private", "Claim")}</Typography>
											</Button>
									)

								}
							</div>
						</div>
					</Grid>
				</Paper>
			</Zoom>
		</div>
	)
}
export default Ido