import { SvgIcon, Link } from "@material-ui/core";
import { ReactComponent as GitHub } from "../../assets/icons/github.svg";
import { ReactComponent as Medium } from "../../assets/icons/medium.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as Temgran } from "../../assets/icons/Temgran.svg";
import { ReactComponent as Discord } from "../../assets/icons/discord.svg";

export default function Social() {
  return (
    <div className="social-row" >
      <Link href="https://discord.gg/jNsZjVrJMV"  target="_blank">
        <SvgIcon color="action" style={{color:"#000000"}} component={Discord} />
      </Link>

      <Link href="https://twitter.com/PrivateDAO_money"  target="_blank">
        <SvgIcon color="primary" style={{color:"#000000"}} component={Twitter} />
      </Link>

      <Link href="https://github.com/PrivateDAO2022" target="_blank">
        <SvgIcon  color="primary"  style={{color:"#000000"}} component={GitHub} />
      </Link>

      <Link href="https://medium.com/@PrivateDAO" target="_blank">
        <SvgIcon color="primary" style={{color:"#000000"}} component={Medium} />
      </Link>
    </div>
  );
}
