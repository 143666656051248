import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Tab,
  Tabs,
  Typography,
  Zoom,
  Tooltip
} from "@material-ui/core";
import NewReleases from "@material-ui/icons/NewReleases";
import RebaseTimer from "../../components/RebaseTimer/RebaseTimer";
import TabPanel from "../../components/TabPanel";
import { trim } from "../../helpers";
import { changeApproval, changeStake, doClaim } from "../../slices/StakeThunk";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./stake.scss";
import { useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { Skeleton } from "@material-ui/lab";
import { error } from "../../slices/MessagesSlice";
import Snowglobe from "../Snowglobe/Snowglobe"
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


function Stake({ theme }) {

  const dispatch = useDispatch();
  const { provider, address, connected, connect, chainID } = useWeb3Context();

  const [zoomed, setZoomed] = useState(false);
  const [view, setView] = useState(0);
  const [quantity, setQuantity] = useState("");
  const isSmallScreen = useMediaQuery("(max-width: 705px)");
  const isMobileScreen = useMediaQuery("(max-width: 513px)");

  const isAppLoading = useSelector(state => state.app.loading);
  const currentIndex = useSelector(state => {
    return state.app.currentIndex;
  });
  const fiveDayRate = useSelector(state => {
    return state.app.fiveDayRate;
  });
  const ohmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.ohm;
  });
  const lock = useSelector(state => {
    return state.account.lock && state.account.lock.GLAlock;
  });
  const warmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.warmBalance;
  });
  const sohmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.sohm;
  });

  const wsohmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.wsohmBalance;
  });
  const stakeAllowance = useSelector(state => {
    return state.account.staking && state.account.staking.ohmStake;
  });
  const unstakeAllowance = useSelector(state => {
    return state.account.staking && state.account.staking.ohmUnstake;
  });
  const stakingRebase = useSelector(state => {
    return state.app.stakingRebase;
  });
  const stakingAPY = useSelector(state => {
    return state.app.stakingAPY;
  });
  const warmupPeriod = useSelector(state => {
    return state.app.warmupPeriod;
  });
  const stakingTVL = useSelector(state => {
    return state.app.stakingTVL;
  });
  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const setMax = () => {
    if (view === 0) {
      setQuantity(ohmBalance);
    } else {
      setQuantity(sohmBalance);
    }
  };

  const onSeekApproval = async token => {
    await dispatch(changeApproval({ address, token, provider, networkID: chainID }));
  };

  const onChangeStake = async action => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(quantity) || quantity === 0 || quantity === "") {
      // eslint-disable-next-line no-alert
      dispatch(error("Please enter a value!"));
    } else {
      await dispatch(changeStake({ address, action, value: quantity.toString(), provider, networkID: chainID }));
    }
  };

  const onClaim = async () => {
    // eslint-disable-next-line no-restricted-globals
    await dispatch(doClaim({ address, provider, networkID: chainID }));
  };


  const hasAllowance = useCallback(
    token => {
      if (token === "ohm") return stakeAllowance > 0;
      if (token === "sohm") return unstakeAllowance > 0;
      return 0;
    },
    [stakeAllowance, unstakeAllowance],
  );

  let modalButton = [];

  modalButton.push(
    <Button variant="contained" color="primary" className="connect-button" onClick={connect} key={1}>
      Connect Wallet
    </Button>,
  );

  const changeView = (event, newView) => {
    setView(newView);
  };

  const stakingRebasePercentage = trim(stakingRebase * 100, 4);
  const nextRewardValue = trim((stakingRebasePercentage / 100) * sohmBalance, 4);

  useEffect(() => {

    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  }, [])

  return (
    <div id="stake-view">
      <Zoom in={true} onEntered={() => setZoomed(true)}>
        <Paper className={`ohm-card`}>
          <Box className="card-header">
            <Typography variant="h5">Single Stake (3, 3)</Typography>
            <RebaseTimer />
          </Box>

          <Grid container item xs={12}>
            <div className="stake-top-metrics">
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <div className="stake-apy">
                    <Typography variant="h5" color="textSecondary">
                      APY
                    </Typography>
                    <Tooltip title={new Intl.NumberFormat("en-US").format(stakingAPY * 100).toString() + " %"} arrow>
                      <Typography variant="h4" style={{ "word-break": "break-all" }}>
                        {stakingAPY ? (
                          new Intl.NumberFormat("en-US").format(stakingAPY * 100).length > 30 ?
                            new Intl.NumberFormat("en-US").format(stakingAPY * 100).substring(0, 30) + "...%" :
                            new Intl.NumberFormat("en-US").format(stakingAPY * 100) + " %"

                        ) : (
                          <Skeleton width="150px" />
                        )}
                      </Typography>
                    </Tooltip>
                  </div>
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <div className="stake-tvl">
                    <Typography variant="h5" color="textSecondary">
                      Total Value Deposited
                    </Typography>
                    <Typography variant="h4">
                      {stakingTVL ? (
                        new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        }).format(stakingTVL)
                      ) : (
                        <Skeleton width="150px" />
                      )}
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <div className="stake-index">
                    <Typography variant="h5" color="textSecondary">
                      Current Index
                    </Typography>
                    <Typography variant="h4">
                      {currentIndex ? <>{trim(currentIndex, 4)} PRIVATE</> : <Skeleton width="150px" />}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className="staking-area" style={{ width: "100%" }}>
              {!address ? (
                <div className="stake-wallet-notification">
                  <div className="wallet-menu" id="wallet-menu">
                    {modalButton}
                  </div>
                  <Typography variant="h6">Connect your wallet to stake PRIVATE</Typography>
                </div>
              ) : (
                <>
                  <Box className="stake-action-area">
                    <Tabs
                      key={String(zoomed)}
                      centered
                      value={view}
                      textColor="primary"
                      indicatorColor="primary"
                      className="stake-tab-buttons"
                      onChange={changeView}
                      aria-label="stake tabs"
                    >
                      <Tab label="Stake" {...a11yProps(0)} />
                      <Tab label="Unstake" {...a11yProps(1)} />
                    </Tabs>
                    <Box className="help-text">
                      {address && ((!hasAllowance("ohm") && view === 0) || (!hasAllowance("sohm") && view === 1)) && (
                        <Typography variant="body2" className="stake-note" color="textSecondary">
                          Note: The "Approve" transaction is only needed when staking/unstaking for the first time;
                          subsequent staking/unstaking only requires you to perform the "Stake" or "Unstake"
                          transaction.
                        </Typography>
                      )}
                    </Box>
                    <Box className="stake-action-row " display="flex" alignItems="center">
                      <FormControl className="ohm-input" variant="outlined" color="primary">
                        <InputLabel htmlFor="amount-input"></InputLabel>
                        <OutlinedInput
                          id="amount-input"
                          type="number"
                          placeholder="Enter an amount"
                          className="stake-input"
                          value={quantity}
                          onChange={e => setQuantity(e.target.value)}
                          labelWidth={0}
                          endAdornment={
                            <InputAdornment position="end">
                              <Button variant="text" onClick={setMax} color="inherit">
                                Max
                              </Button>
                            </InputAdornment>
                          }
                        />
                      </FormControl>

                      <TabPanel value={view} index={0} className="stake-tab-panel">
                        {address && hasAllowance("ohm") ? (
                          <Button
                            className="stake-button"
                            variant="contained"
                            color="primary"
                            disabled={isPendingTxn(pendingTransactions, "staking")}
                            onClick={() => {
                              onChangeStake("stake");
                            }}
                          >
                            {txnButtonText(pendingTransactions, "staking", "Stake")}
                          </Button>
                        ) : (
                          <Button
                            className="stake-button"
                            variant="contained"
                            color="primary"
                            disabled={isPendingTxn(pendingTransactions, "approve_staking")}
                            onClick={() => {
                              onSeekApproval("ohm");
                            }}
                          >
                            {txnButtonText(pendingTransactions, "approve_staking", "Approve")}
                          </Button>
                        )}
                      </TabPanel>

                      <TabPanel value={view} index={1} className="stake-tab-panel">
                        {address && hasAllowance("sohm") ? (
                          <Button
                            className="stake-button"
                            variant="contained"
                            color="primary"
                            disabled={isPendingTxn(pendingTransactions, "unstaking")}
                            onClick={() => {
                              onChangeStake("unstake");
                            }}
                          >
                            {txnButtonText(pendingTransactions, "unstaking", "Unstake")}
                          </Button>
                        ) : (
                          <Button
                            className="stake-button"
                            variant="contained"
                            color="primary"
                            disabled={isPendingTxn(pendingTransactions, "approve_unstaking")}
                            onClick={() => {
                              onSeekApproval("sohm");
                            }}
                          >
                            {txnButtonText(pendingTransactions, "approve_unstaking", "Approve")}
                          </Button>
                        )}
                      </TabPanel>
                    </Box>
                  </Box>

                  <div className={`stake-user-data`}>
                    <div className="data-row">
                      <Typography variant="body1">Your Balance</Typography>
                      <Typography variant="body1">
                        {isAppLoading ? <Skeleton width="80px" /> : <>{trim(ohmBalance, 4)} PRIVATE</>}
                      </Typography>
                    </div>
                    <div className="data-row">
                      <Typography variant="body1">Your Warmup Balance ({trim(warmupPeriod)} epochs)</Typography>
                      <Typography variant="body1">
                        {isAppLoading ? <Skeleton width="80px" /> :
                          <>
                            {trim(warmBalance, 4)} sPRIVATE {warmBalance > 0 ? lock ? " (Locked)" :
                              <Button
                                style={{ padding: "0 10px" }}
                                className="claim-button"
                                variant="outlined"
                                color="primary"
                                disabled={isPendingTxn(pendingTransactions, "ClaimsKARMA")}
                                onClick={() => {
                                  onClaim()
                                }}
                              >
                                {txnButtonText(pendingTransactions, "ClaimsKARMA", "Claim")}
                              </Button> : ""}
                          </>}
                      </Typography>
                    </div>
                    <div className="data-row">
                      <Typography variant="body1">Your Staked Balance</Typography>
                      <Typography variant="body1">
                        {isAppLoading ? <Skeleton width="80px" /> : <>{trim(sohmBalance, 4)} sPRIVATE</>}
                      </Typography>
                    </div>
                    <div className="data-row">
                      <Typography variant="body1">Your Wrapped Balance</Typography>
                      <Typography variant="body1">
                        {isAppLoading ? <Skeleton width="80px" /> : <>{trim(wsohmBalance, 4)} wPRIVATE</>}
                      </Typography>
                    </div>
                    <div className="data-row">
                      <Typography variant="body1">Next Reward Amount</Typography>
                      <Typography variant="body1">
                        {isAppLoading ? <Skeleton width="80px" /> : <>{nextRewardValue} sPRIVATE</>}
                      </Typography>
                    </div>

                    <div className="data-row">
                      <Typography variant="body1">Next Reward Yield</Typography>
                      <Typography variant="body1">
                        {isAppLoading ? <Skeleton width="80px" /> : <>{new Intl.NumberFormat("en-US").format(stakingRebasePercentage)}%</>}
                      </Typography>
                    </div>

                    <div className="data-row">
                      <Typography variant="body1">ROI (5-Day Rate)</Typography>
                      <Typography variant="body1">
                        {isAppLoading ? <Skeleton width="80px" /> : <>{trim(fiveDayRate * 100, 4)}%</>}
                      </Typography>
                    </div>
                  </div>
                  <Box className="help-text">
                    <Typography variant="body2" className="stake-note" color="textSecondary">
                      Note: There is a {trim(warmupPeriod)} epoch warm-up staking period, where users must be staked for more than {trim(warmupPeriod)} epoch before claiming your funds.
                      Users will accumulate rewards while in the warm-up period but will not be able to claim them for {trim(warmupPeriod)} epoch.
                      When {trim(warmupPeriod)} epoch has elapsed your warmup balance can be claimed from the warmup contract to your wallet and receive the rebase rewards continuously.
                    </Typography>
                  </Box>
                </>
              )}
            </div>
          </Grid>
        </Paper>
      </Zoom>
      {/* <Snowglobe theme={theme}></Snowglobe> */}
    </div>
  );
}

export default Stake;
